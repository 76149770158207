import React, { useState, useEffect } from 'react';
import { YMaps, Map as YMap, ZoomControl } from 'react-yandex-maps';
import { makeStyles } from '@material-ui/core/styles';
import { useMapComponent } from '../../hooks';
import { getScrollWidth } from '../../utils';
import getDefaultTemplates from './getTemplates';
import clsx from 'clsx';
const initialQuery = {
		ns: 'use-load-option',
		load: 'package.full',
		apikey: 'ce063af1-21c5-4d13-9262-16bd241a3913'
	},
	initialDefaultState = { center: [ 55.662693, 37.558416 ], zoom: 8, controls: [] },
	initialDefaultOptions = { autoFitToViewport: true, suppressMapOpenBlock: true };


export default React.memo ( MapComponent );

function MapComponent ({ className, query = initialQuery, defaultState = initialDefaultState, defaultOptions = initialDefaultOptions, getTemplates = () => {}, children })
{
	const classes = useStyles(),
		[ width, setWidth ] = useState ( getScrollWidth ),
		{ options, setMap, setYmaps, setTemplates } = useMapComponent();

	useEffect (
		() => {
			const listener = () => setWidth ( getScrollWidth() );

			window.addEventListener ( 'resize', listener );

			return () => window.removeEventListener ( 'resize', listener );
		},
		[]
	);

	return (
		<YMaps {...{ query }}>
			<YMap
				style={{ width }}
				className={ clsx ( classes.map, !options.colors && classes.greyscale, className ) }
				instanceRef={ setMap }
				onLoad={ api => {
					setYmaps ( api );
					setTemplates ({
						...getDefaultTemplates ( api ),
						...getTemplates ( api )
					})
				} }
				{...{ defaultState, defaultOptions }}
			>
				<ZoomControl />
				{ children }
			</YMap>
		</YMaps>
	);
}


const useStyles = makeStyles ( theme => ({
	map: {
		flex: 1,
		'& ymaps[class*=ground]': {
			filter: 'grayscale(0)',
			transition: 'filter ease-in 0.3s'
		}
	},
	greyscale: {
		'& ymaps[class*=ground]': {
			filter: 'grayscale(100%)'
		}
	}
}) );
